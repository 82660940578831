textarea:focus {
  box-shadow: none !important;
}

.error-message {
  border: none;
  background: none;
}

.image-upload-preview {
  .delete-icon-wrapper {
    position: absolute;
    right: 20px;
    margin-top: -40px;
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 20px;
    padding-top: 10px;
  }

  position: relative;
  outline: none;
  padding: 0;
  border: none;
  background: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 120px;
  border-radius: 4px;
  border: 1px solid rgba(164, 173, 186, 0.2);
  margin-bottom: 24px;
  font-size: 14px;
  color: #a4adba;
}

$header_height: 55px;
header {
  line-height: $header_height;
  height: $header_height;

  .title {
    line-height: $page-header-height;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    h1 {
      font-weight: 300;
      font-size: 18px;
    }

    h2 {
      font-weight: 150;
    }
  }

  .header-description {
    left: 55px;
    right: 55px;
    color: #fff;
    font-size: 14px;
  }

  button.btn {
    height: $header_height;
    line-height: $header_height;
  }
}

.blur_bg {
  -webkit-filter: url("#blur");
  filter: url("#blur");
  -webkit-filter: blur(5px);
  filter: blur(5px);
  background-size: cover;
  opacity: 0.5;
}

.image-upload-preview img {
  max-height: 120px;
}

.scroll-touch {
  height: calc(100vh - 55px);
  -webkit-overflow-scrolling: touch; /* Lets it scroll lazy */
}

input[type="datetime-local"].form-control,
input[type="time"].form-control,
input[type="date"].form-control {
  line-height: 20px;
  height: 48px;
  background: #ffffff !important;

  &.disabled {
    background: #eee !important;
  }
}

input[type="checkbox"].disabled {
  opacity: 0;
}

.flip-switch__input:checked.disabled + .flip-switch__bar {
  background: #a4adba;
}

.mg-t-70 {
  margin-top: 70px !important;
}

.mg-l-10 {
  margin-left: 10px !important;
}

.sked-pop-up {
  height: 50vh;
  margin: -20px;
  overflow-x: hidden;

  .sked-item-panel {
    .list-item {
      border: none;
    }

    .pop-up-lst-items {
      height: calc(50vh - 158px);
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}

.border-none {
  border: none !important;
}

.lookup-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 40px;
  background: #fff url(../static/forms/images/search.svg) no-repeat right 20px center;
  height: 48px;
}

.camera-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 20px;

  .camera-item {
    z-index: 2;
    width: 64px;
    height: 64px;
    bottom: 26px;
    border-radius: 50%;
    border-color: transparent;
    border: none;
  }
}

.pad-all {
  padding: 16px;
}

.flex {
  display: flex;
}

.border-none {
  border: 0;
}

.flex-between-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.sticky-block {
  position: sticky;
  top: 0;
  z-index: 9999;
  background-color: #eee;
}

.bar-title {
  font-weight: 500;
}

.sign-wrapper {
  display: flex;
  flex-direction: column;
  height: -webkit-fill-available;

  .signature-box-wrapper {
    flex-grow: 1;
    display: flex;

    .signature-box {
      border: 1px dashed #dddddd;
      position: relative;
      flex: 1;

      .canvas {
        // background: grey;
        width: 100%;
        height: calc(100% - 50px);
      }

      .signature-text {
        position: absolute;
        bottom: 10px;
        width: 100%;
      }
    }
  }

  .btn-wrapper {
    margin: 16px;
    display: flex;
    justify-content: space-around;

    .btn-group-2 {
      justify-content: space-evenly;

      .btn {
        width: 45%;
      }
    }
  }
}

.warning {
  display: flex;
  padding: 16px;

  &.not-requested {
    background: #ec9a7036;

    .icon {
      background: #ec9a70;
    }
  }

  &.requested-sent {
    background-color: #70ec8636;

    .icon {
      background-color: #70ec86;
    }
  }

  &.error {
    background-color: #ff000036;

    .icon {
      background-color: #ff0000;
    }
  }

  .icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    text-align: center;
    font-size: 0.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
  }

  .message {
    margin-left: 10px;
  }
}

#root > div {
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  overflow-x: hidden;
  height: -webkit-fill-available;

  .bar-title {
    flex: 0 0 auto;
    position: relative;
  }

  & > section {
    flex: 1 1 auto;
    overflow-y: auto;
    padding-top: 0;

    .sign-wrapper {
      display: flex;
      flex-direction: column;
      height: auto;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;

      .content-section,
      .btn-wrapper {
        flex: none;
      }

      .signature-box-wrapper {
        flex: 1 1 auto;
      }
    }
  }
}
