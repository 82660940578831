.fade-appear {
  opacity: 0.01;
  visibility: hidden;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  // transition: opacity 600ms ease-in;
  visibility: visible;
}

.fade-enter {
  opacity: 0.01;
  visibility: hidden;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 600ms ease-in;
  transition-delay: 50ms;
  visibility: visible;
}

.fade-leave {
  opacity: 1;
  visibility: visible;
}

.fade-leave.fade-leave-active {
  opacity: 0.01;
  visibility: hidden;
}

.spicy-enter {
  opacity: .01;
  transform: scaleX(0) translateY(100px);
}

.spicy-enter.spicy-enter-active {
  opacity: 1;
  transform: scaleX(1) translateY(0);
  transition: all 500ms ease-in-out;
}

.spicy-leave {
  opacity: 1;
  transform: scaleX(1) translateY(0);
}

.spicy-leave-active {
  opacity: 01;
  transform: scaleX(0) translateY(100px);
  transition: all 300ms ease-in-out
}

.slide-enter {
  transform: translate(100%);
}
.slide-enter.slide-enter-active {
  transform: translate(0%);
  transition: transform 500ms ease-in-out;
}
.slide-leave {
  transform: translate(0%);
}
.slide-leave.slide-leave-active {
  transform: translate(-100%);
  transition: transform 300ms ease-in-out;
}

.modal {
  background: rgba(4, 4, 15, 0.4) !important;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 60px;
  height: 60px;
  margin: 6px;
  border: 6px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}