//
// Forms
// --------------------------------------------------
@mixin disabled {
  &:disabled {
    background-color: #eee;
    opacity: 0.9;
    cursor: not-allowed;
  }
}

label,
.label{
    margin-bottom: 10px;
    display: block;
}

//select,
//textarea,
//input[type="text"],
//input[type="search"],
//input[type="password"],
//input[type="datetime"],
//input[type="datetime-local"],
//input[type="date"],
//input[type="month"],
//input[type="time"],
//input[type="week"],
//input[type="number"],
//input[type="email"],
//input[type="url"],
//input[type="tel"],
//input[type="color"],
//input:not([type]) {
//    width: 100%;
//    height: $input-height;
//    padding: 10px 6px;
//    font-family: inherit;
//    font-size: inherit;
//    line-height:normal;
//    background: #fff;
//    color:$base-color;
//    border: 1px solid $form_element_border_color;
//    border-radius: $input-border-radius;
//    box-shadow: 0 1px 1px rgba(255, 255, 255, .2), inset 0 1px 1px rgba(0, 0, 0, .1);
//    @include appearance(none);
//    @include form-control-focus;
//}

// Focus for file, radio, and checkbox
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  @include tab-focus;
}

textarea{
    height: 150px;
    resize: none;
    vertical-align:top;
}
select {
    background: url("#{$images-path}select2x.png") no-repeat top right #fff;
    background-size: $input-height $input-height;
    padding: 0px 46px 0px 8px;
    @include appearance(none);
}

// Force user-select on inputs and textareas
input, textarea {
    -webkit-user-select: text !important;
    -khtml-user-select: text !important;
    -moz-user-select: text !important;
    -ms-user-select: text !important;
    -o-user-select: text !important;
    user-select: text !important;  
}

// Firefox HACKS
@-moz-document url-prefix() {
    select {
        // remove the arrow from a <select> tag in Firefox HACK
        -moz-appearance: none;
        text-indent: 0.01px;
        text-overflow: '';
        padding-top:10px;
    }
}

.required label, 
label.required,
.required .label, 
.label.required{
    border-left: 2px solid $color_red;
    padding-left: 4px;
}
// Form Error

.input-error, input.error-message{
    border: 1px solid $color_red !important;
}
.error-message{
    text-align:left;
    margin: 5px 0;
    padding: 3px 6px;
    display: block;
    background: #f6e3e3;
    color: #df4040;
    border: 1px solid $color_red;
    overflow:hidden;
}