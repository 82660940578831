///////////////////////////////////////////
// Checkbox
///////////////////////////////////////////
.checkbox {
    position: relative;
    display: inline-block;
    padding: 0;
    margin: 0;
    cursor: pointer;
    input{
        position: relative;
        width: $checkbox-size;
        height: $checkbox-size;
        border: 0;
        margin: 0;
        line-height: normal;
        background: transparent;
        cursor: pointer;
        -webkit-appearance: none;
        &:focus{outline: 0}
        &:before {
            border: 1px solid $color_grey;
            // what the checkbox looks like when its not checked
            display: table;
            width: 100%;
            height: 100%;
            margin: 0;
            @include border-radius(4px);
            background: #fff;
            content: ' ';
            transition: background-color 0.1s ease-in-out;
        }
        &:checked:before {background: $color_green;border-color: darken($color_green, 10%)}
        &:after {
            -webkit-transition: opacity 0.05s ease-in-out;
            -moz-transition: opacity 0.05s ease-in-out;
            transition: opacity 0.05s ease-in-out;
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            transform: rotate(-45deg);
            position: absolute;
            top: 30%;
            left: 25%;
            display: table;
            width: 15px;
            height: 8.33333px;
            border: 2px solid #fff;
            border-top: 0;
            border-right: 0;
            content: ' ';
            opacity: 0;
        }
        &:checked:after {
            // what the checkmark looks like when its checked
            opacity: 1;
        }
    }
}

// Checkbox list
.item-checkbox {
    min-height: 40px !important;
    padding: 0 !important;
    -webkit-transition: background-color 0.2s linear;
    -moz-transition: background-color 0.2s linear;
    -o-transition: background-color 0.2s linear;
    transition: background-color 0.2s linear;
    &:active{
        background: darken(#fff, 5%) !important;
        -webkit-transition: none;
        -moz-transition: none;
        -o-transition: none;
        transition: none;
    }
    &.active {
        box-shadow: none;
    }
    .checkbox {
        padding: $content-padding $content-padding $content-padding ($content-padding + $content-padding + $checkbox-size);
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -moz-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        -moz-align-items: center;
        align-items: center;
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 3;
    }
    input{
        position:absolute;
        left: $content-padding;
        top:50%;
        margin-top: -$checkbox-size / 2
    }
}