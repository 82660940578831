h1, h2, h3, h4, h5, h6{
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    font-family: $headings-font-family;
}

h1, .h1 { font-size: $font-size-h1; }
h2, .h2 { font-size: $font-size-h2; }
h3, .h3 { font-size: $font-size-h3; }
h4, .h4 { font-size: $font-size-h4; }
h5, .h5 { font-size: $font-size-h5; }
h6, .h6 { font-size: $font-size-h6; }

p {
    margin: 0 0 10px;
    &:last-child{
        margin-bottom: 0;
    }
}

ul,
ol {
    display:block;
    margin-top: 0;
    margin-bottom: $content-padding;
    margin-left: $content-padding;
    ul,
    ol {
        margin-bottom: 0;
    }
}

ol, ul {
    &.clear{
        list-style: none;
        margin:0;
    }
}

blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

.text-small{font-size: 80%;}
.text-medium{font-size: 1.5em;font-weight: bold;}
.text-large{font-size: 3em;font-weight: bold;}
.uppercase{text-transform: uppercase;}

.center,
.text-center{
    text-align: center;
}
.al,
.text-left{
    text-align: left;
}
.ar,
.text-right{
    text-align: right !important;
}