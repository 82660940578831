//
// Modals
// --------------------------------------------------
// IMPORTANT: Can't have transition/animations or JAWS virtual buffer will fail to get the page content
@mixin modal-style{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    overflow: hidden;
    z-index: 11;
    opacity:0;
    &.active{
        display: block;
        opacity:1;
    }
}

.modal{
    @include modal-style;
    background: url("#{$images-path}popup-bg.png") repeat transparent;
    background-size: 10px 10px;
}

.modal-box {
    position: absolute;
    top: 50px;
    left: 3%;
    width: 94%;
    height: auto;
    overflow: hidden;
    background-color: #fff;
    color:$base-color;
    @include box-shadow(0px 0px 8px 0px rgba(0, 0, 0, 0.3));
    @include border-radius(3px);
    .modal-header{
        position:relative;
        width: 100%;
        padding: $content-padding;
        font-size: 1.2em;
        line-height: 1.4em;
        border-bottom: 1px solid #c6c6c6;
    }
    .modal-body{
        width: 100%;
        padding: 15px;
        @include clearfix();
    }
}
.close-modal{
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    color:#c9cacc;
    padding-top:5px;
    &.btn i, i{font-size:24px;color:$color_grey_light}
    &:active{
        -webkit-box-shadow:none;
        -moz-box-shadow: none;
        box-shadow:none;
    }
}

@media screen and (min-width: $tablet_breakpoint) {
    .modal-box {
        left: 20%;
        width: 60%;
    }
}

// Prompt confirmation popup
.prompt {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300px;
    transform: translate(-50%, -50%);
    overflow: hidden;
    background-color: #fff;
    box-shadow:0px, 0px, 8px, 0px, rgba(0, 0, 0, 0.3);
    @include border-radius(3px);
    .prompt-header{
        width: 100%;
        font-weight: bold;
        padding: $content-padding;
        font-size: 1.2em;
        line-height: 1.4em;
        border-bottom: 1px solid #c6c6c6;
        color: #555555;
    }
    .prompt-body{
        text-align: center;
        width: 100%;
        padding: $content-padding;
        @include clearfix();
    }
}
