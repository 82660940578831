///////////////////////////////////////////
// Radio
///////////////////////////////////////////
.radio {
    position: relative;
    display: inline-block;
    padding: 0 7px;
    margin: 0;
    cursor: pointer;
    input{
        position: relative;
        width: 28px;
        height: 28px;
        border: 0;
        margin: 0;
        line-height: normal;
        background: transparent;
        cursor: pointer;
        -webkit-appearance: none;
        &:focus{outline: 0}
        &:before {
            border: 1px solid $color_grey;
            // what the checkbox looks like when its not checked
            display: table;
            width: 100%;
            height: 100%;
            margin: 0;
            @include border-radius(14px);
            background: #fff;
            content: ' ';
            transition: background-color 0.1s ease-in-out;
        }
        &:checked:before {background: $color_green;border-color: darken($color_green, 10%)}
        &:after {
            -webkit-transition: opacity 0.05s ease-in-out;
            -moz-transition: opacity 0.05s ease-in-out;
            transition: opacity 0.05s ease-in-out;
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -6px 0 0 -6px;
            display: table;
            width: 12px;
            height: 12px;
            background: #fff;
            @include border-radius(6px);
            content: ' ';
            opacity: 0;
        }
        &:checked:after {
            // what the checkmark looks like when its checked
            opacity: 1;
        }
    }
}


// Radio list
.item-radio{
    padding: $item-border-width 0 !important;
    margin: $item-border-width * -1 !important;
    display: block;
    -webkit-transition: background-color 0.2s linear;
    -moz-transition: background-color 0.2s linear;
    -o-transition: background-color 0.2s linear;
    transition: background-color 0.2s linear;
    &:active{
        background: darken(#fff, 5%) !important;
        -webkit-transition: none;
        -moz-transition: none;
        -o-transition: none;
        transition: none;
    }
    .item-content {
        position: relative;
        z-index: 2;
        padding: 15px 60px 15px 15px;
        border: none;
    }
    .itemRight {
        color:$color_green;
        font-size:22px;
        visibility: hidden;
    }
    > input {
        position: absolute;
        overflow: hidden;
        right: 0px;
        top: 0px;
        left: 0px;
        bottom: 0px;
        padding: 0;
        border: 0;
        opacity: 0;
        z-index: 3;
        vertical-align: top;
        outline: none;
        width: 100%;
        height: 100%;
        margin: 0;
        &:checked ~ .itemRight{
            margin-top: -12px;
            visibility: visible;
        }
        &:active {
            outline: 0;
        }
    }
}

body.android .item-radio {-webkit-animation: androidCheckedbugfix infinite 1s;}

@-webkit-keyframes androidCheckedbugfix {
  from {
    padding: 0; }

  to {
    padding: 0; }
}