
body.signature-active #modal-signature{
    display: block !important;
    opacity:1;
    z-index: 999;
}

#modal-signature{
    display: none;
    .modal-box{
        top: 50%;
        left:50%;
        width:460px;
        margin-top:-135px;
        margin-left:-230px;
    }
    #canvas-div, .btn-group{display:block}
    canvas {
        position: relative;
        width: 100% !important;
        height: calc(100% - 51px) !important;
        min-height: 150px;
    }
}
.rotateNotification{
    display: none;
}

#canvas-div{
    width: 100%;
    min-width: 270px;
    max-height: 150px;
    background: #fff;
    border:1px solid $color_grey_dark;
    margin: 0 0 10px 0;
    overflow: hidden;
    @include box-sizing();
    @include border-radius(10px);
}
#canvas-pd{
    padding: 3px;
    overflow: hidden;
    @include box-sizing();
}

@media screen and (max-width: 460px) {
    #modal-signature{
        .modal-box{
            top: 50%;
            width:300px;
            left:50%;
            margin-left:-150px;
            margin-top:-52px;
            .drawing{
                display: none;
            }
            .rotateNotification{
                display: block;
                line-height: 20px;
            }
        }
        #canvas-div, .btn-group{display:none}
    }
}

.signature-img{
    width: 100%;
    max-width: 640px;
}
