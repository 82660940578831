.sticky-button {
  position: fixed;
  z-index: 2;
  width: 64px;
  height: 64px;
  bottom: 26px;
  left: 50%;
  transform: translateX(-50%);

  .sticky-list-actions__btn {
    padding: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;

    position: absolute;
    left: 0;
    top: 0;

    transition-property: opacity, transform;
    transition-duration: 0.3s;

    &--trash {
      opacity: 0;

      &:before {
        background: url('../static/forms/images/icon-trash-black.svg');
      }
    }

    &--add,
    &--add-remove {
      &:before {
        background: url("../static/forms/images/icon-plus-white.svg");
      }
    }

    &--add-remove {
      &:after {
        background: url("../static/forms/images/icon-cross-white-2.svg");
      }
    }

    &--edit {
      &:before {
        background: url("../static/forms/images/edit.svg");
      }
    }
  }

  &--expanded &__btn:first-child {
    transform: translateX(calc(-50% - 8px));
    opacity: 1;
  }

  &--expanded &__btn:last-child {
    transform: translateX(calc(50% + 8px));
  }

  &--expanded &__btn--add-remove {
    &:before {
      transform: translate(-50%, -50%) scale(0);
    }

    &:after {
      transform: translate(-50%, -50%) scale(1);
    }
  }
}