// Loading
.modal-loading-box{
    width: 100%;
    height: 100px;
    display: block;
    position: absolute;
    top: 50%;
    margin-top:-50px;
    text-align:center;
}

.spinner-lines{
    svg {
        width: 20%;
        height: 85px;
    }
    &.blue{
        stroke: $main_color;
        fill: $main_color;
    }
    &.white{
        stroke: #fff;
        fill: #fff;
    }
}

.modal-loading-ticker {
  position:absolute;
  width:32px;
  height:32px;
  left:50%;
  top:50%;
  margin-left:-16px;
  margin-top:-16px;
  @include border-radius(100%);
  display: block;
}

.lock-screen {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: $z-index-loading;
}